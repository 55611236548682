import logo from './logo.svg';
import './App.css';
import { useState } from "react";

function App() {
  const [location, setlocation] = useState("");
  const [weather, setWeather] = useState({});

const hndelSuvmit = async() =>{
  let res = await fetch(`/Weather?location=${location}`);
  let data = await res.json();
  setWeather(data)
}

  return (
    <div className="App">
      <h1>תחזית מזג האויר</h1>
      
       <input
       id='id'
       value={location}
       onChange={((e) => setlocation(e.target.value))}
       />
       <label htmlFor='id'>הכנס עיר</label>
       <br />
       <button onClick={hndelSuvmit}>שלח</button>
       <p>{weather.current?.temp_c}</p>
       <p>{weather.current?.condition.text}</p>
       <img src={weather.current?.condition.icon} />
       <p>Built by Moti Software Systems</p>
    </div>
  );
}

export default App;
